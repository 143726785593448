import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {DialogTitle,Dialog,DialogContent,DialogContentText,DialogActions,Button} from '@mui/material'
import {setDeleteID,setDelete} from '../actions/userActions'
import _ from 'lodash'


function DialogAnswer(props) {
  const {deleteID} = useSelector((state) => state.contents)
  const dispatch = useDispatch()
  return(
    <Dialog
      open={!_.isEmpty(deleteID)}
      onClose={()=>dispatch(setDeleteID(''))}
    >
      <DialogTitle>
        {"Cảnh báo"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Xác nhận xoá dữ liệu. 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>dispatch(setDeleteID(''))}>Huỷ</Button>
        <Button onClick={()=>props.confirmVB(deleteID)}>
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogAnswer