import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Stack,
  Slide,
  Snackbar,styled, Alert
} from "@mui/material";
import { loginRequest } from "../authConfig";
import { useMsal, useAccount } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "gatsby";
import {
  setControlThemVB,
  getVB,
  setEdit,
  setPageSize,
  setDeleteID,
  setDelete,
  setAuth,
  importVanBan
} from "../actions/userActions";
import Layout from "../components/layout";
import {LinhVuc,NoiBH} from '../data/data.json'
import SelectComponent from "../components/selectComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ThemVanBanComponent from "../components/dialogThemVanBan";
import ImportVanBanComponent from '../components/dialogImportVanBan'
import { NamBanHanh_contain } from "../utils/contains";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import YesNoComponent from "../components/dialogYesNo";
import Footer from "../components/footer";
import _ from "lodash";


// const Input = styled('input')({
//   display: 'none',
// });

function CustomToolbar() {
  const dispatch = useDispatch();
  // const handleOpenFile= (event)=>{
  //   if(event.target.files && event.target.files.length > 0){
  //     var files = event.target.files, f = files[0];
  //     const reader = new FileReader();
  //     reader.onload = (evt) => {
  //       const data = evt.target.result;
  //       let readedData = XLSX.read(data, {type: 'binary'});
  //       const wsname = readedData.SheetNames[0];
  //       const ws = readedData.Sheets[wsname];
  //       const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
  //       dispatch(importVanBan(dataParse))
  //     }
  //     reader.readAsBinaryString(f);
  //   }
  // }
  return (
    <GridToolbarContainer style={{ height: "50px" }}>
      <Stack
        style={{ width: "100%" }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        {/* <label htmlFor="contained-button-file" className='text-center'>
          <Input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="contained-button-file" type="file" onChange={handleOpenFile}/>
          <Button variant="contained" size="small" startIcon={<AddCircleOutlineIcon />} component="span">
          Import văn bản
          </Button>
        </label> */}
        <Button
          variant="contained"
          size="small"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => dispatch(setControlThemVB())}
        >
          Thêm văn bản
        </Button>
      </Stack>
    </GridToolbarContainer>
  );
}
const columns = [
  {
    field: "KyHieu",
    headerName: "Số/Ký hiệu",
    flex: 0.2,
    width: 150,
    renderCell: (params) => (
      <Link to={`/details?id=${params.id}`}>{params.value}</Link>
    ),
  },
  {
    field: "NgayBanHanh",
    headerName: "Ngày ban hành",
    type: "date",
    valueGetter: (value) => dayjs(value.row.NgayBanHanh).format("DD/MM/YYYY"),
    flex: 0.2,
    width: 150,
  },
  {
    field: "TrichYeu",
    headerName: "Trích yếu",
    flex: 0.6,
    renderCell: (params) => (
      <Link to={`/details?id=${params.id}`}>{params.value}</Link>
    ),
  },
];

function IndexPage(params) {
  const NamBanHanh_data = NamBanHanh_contain();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { isAdmin, accessToken } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const { vanBan, pageSize, snackMessage } = useSelector(
    (state) => state.contents
  );
  const [states, setStates] = React.useState({
    LinhVuc: "all",
    NoiBH: "all",
    NamBanHanh: 0,
    search: "",
  });
  const columnsAD = React.useMemo(
    () => [
      {
        field: "KyHieu",
        headerName: "Số/Ký hiệu",
        flex: 0.2,
        width: 150,
        renderCell: (params) => (
          <Link to={`/details?id=${params.id}`}>{params.value}</Link>
        ),
      },
      {
        field: "NgayBanHanh",
        headerName: "Ngày ban hành",
        type: "date",
        valueGetter: (value) =>
          dayjs(value.row.NgayBanHanh).format("DD/MM/YYYY"),
        flex: 0.2,
        width: 150,
      },
      {
        field: "TrichYeu",
        headerName: "Trích yếu",
        flex: 0.6,
        renderCell: (params) => (
          <Link to={`/details?id=${params.id}`}>{params.value}</Link>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => dispatch(setDeleteID(params.id))}
          />,
        ],
        flex: 0.2,
      },
    ],
    []
  );
  
  const refeshToken = (callback) => {
    if (account && inProgress === InteractionStatus.None) {
      const request = {
        ...loginRequest,
        account: account,
      };
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          callback({
            accessToken: response.accessToken,
            expiresOn: response.expiresOn,
          });
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(request);
          }
        });
    }
  };
  function getVanBan(token) {
    dispatch(
      getVB(states, token, (error) => {
        if (error.response.status === 401) {
          refeshToken((reToken) => {
            setAuth(reToken);
            getVanBan(reToken.accessToken);
          });
        }
      })
    );
  }
  function deleteVB(id,token) {
    dispatch(setDelete(id,token,(error)=>{
      if (error.response.status === 401) {
        refeshToken((reToken) => {
          setAuth(reToken);
          deleteVB(id,reToken.accessToken);
        });
      } else if (error.response.status === 400){
        alert("Bạn không có quyền xoá trên Văn bản này!")
        dispatch(setDeleteID(null))
      }
    }))
  }
  React.useEffect(() => {
    if (!_.isEmpty(accessToken)) {
      getVanBan(accessToken);
    }
  }, [accessToken]);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            pt={2}
            pb={2}
          >
            <img
              src="https://hunghau.vn/wp-content/uploads/2022/02/logo-HHH-new.png"
              width={100}
              height={100}
              alt="Logo HungHau"
            />
            <div>
              <Link to="https://hunghau.vn" target="_blank" style={{textDecoration:'none'}}>
                <Typography variant="h3" color="#0565ac">
                  <strong>hunghau.vn</strong>
                </Typography>
              </Link>
              
              {""}
              <Typography textAlign="center">
                {dayjs().format("dddd, DD/MM/YYYY")}
              </Typography>
            </div>
          </Stack>
          <Box sx={{ p: 3,mb:3, border: "1px solid grey", "border-radius": "10px" }}>
            <Typography variant="h6" gutterBottom>
              TÌM KIẾM VĂN BẢN
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <SelectComponent
                  label="Lĩnh vực"
                  values={[{"name": "-- Tất cả --", "value": "all"},...LinhVuc]}
                  value={states.LinhVuc}
                  onChange={(val) =>
                    setStates((prev) => ({ ...prev, LinhVuc: val }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectComponent
                  label="Cơ quan ban hành"
                  values={[{"name": "-- Tất cả --", "value": "all"},...NoiBH]}
                  value={states.NoiBH}
                  onChange={(val) =>
                    setStates((prev) => ({ ...prev, NoiBH: val }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectComponent
                  label="Năm ban hành"
                  values={NamBanHanh_data}
                  value={states.NamBanHanh}
                  onChange={(val) =>
                    setStates((prev) => ({ ...prev, NamBanHanh: val }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Tìm kiếm"
                  fullWidth
                  placeholder="Nhập từ khoá"
                  size="small"
                  value={states.search}
                  onChange={(e) =>
                    setStates((prev) => ({ ...prev, search: e.target.value }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectComponent
                  label="Số lượng kết quả"
                  values={[
                    { name: 10, value: 10 },
                    { name: 20, value: 20 },
                    { name: 30, value: 30 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 }
                  ]}
                  value={pageSize}
                  onChange={(val) => dispatch(setPageSize(val))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  onClick={()=>getVanBan(accessToken)}
                >
                  Tìm kiếm
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="h6" gutterBottom>
            TẤT CẢ VĂN BẢN
          </Typography>

          <DataGrid
            initialState={{
              pagination: {
                pageSize: pageSize,
              },
            }}
            pageSize={pageSize}
            disableSelectionOnClick
            autoHeight
            components={
              isAdmin
                ? {
                    Toolbar: CustomToolbar,
                  }
                : {}
            }
            columns={isAdmin ? columnsAD : columns}
            rows={vanBan}
            getRowId={(rows) => rows._id}
            rowsPerPageOptions={[]}
          />
          <ThemVanBanComponent />
          <ImportVanBanComponent/>
          <YesNoComponent confirmVB={(id)=>deleteVB(id,accessToken)} />
          <Snackbar
            open={!_.isEmpty(snackMessage)}
            message={snackMessage}
            TransitionComponent={(props) => <Slide {...props} direction="up" />}
          />
        </Container>
        <Footer />
      </Box>
    </Layout>
  );
}

export default IndexPage;
