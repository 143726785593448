import React, { PureComponent } from 'react'
import {Dialog, DialogTitle} from '@mui/material'
import { useSelector } from 'react-redux'

function ImportVanBan(props) {
  const {isCompleted,status,range} =useSelector(state=>state.contents.imports)
  return(
    <Dialog open={!isCompleted}>
      <DialogTitle>onProcess {status}/{range}</DialogTitle>
    </Dialog>
  )
}

export default ImportVanBan