import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import { setControlThemVB, setThemVB } from "../actions/userActions";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import {Link} from 'gatsby'
import {LoaiVB,NoiBH,LinhVuc} from '../data/data.json'
import queryString from 'query-string'
import _ from 'lodash'

function ThemVanBanComponent(params) {
  const { accessToken,userInfo } = useSelector((state) => state.auth);
  const { isThemVanBan, vanBan } = useSelector((state) => state.contents);
  const [inputCache, setInputCache] = useState({
    LoaiVB: "",
    LinhVuc: "",
    NoiBH: ""
  })
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setControlThemVB());
  };
  const [state, setState] = useState({
    KyHieu: "",
    LoaiVB: "",
    NoiBH: "",
    TrichYeu: "",
    NguoiKy: "",
    LinhVuc: "",
    TepDinhKem: "",
    TenTep:"",
    NgayBanHanh: new Date(),
  });
  const handleSave = () => {
    if (accessToken) {
      dispatch(
        setThemVB(state, accessToken, () => {
          setState({
            KyHieu: "",
            LoaiVB: "",
            NoiBH: "",
            TrichYeu: "",
            NguoiKy: "",
            LinhVuc: "",
            TepDinhKem: "",
            TenTep:"",
            NgayBanHanh: new Date(),
          });
        })
      );
    }
  };
  const handleChange = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };
  const handleInput = (key,value) =>{
    setInputCache((pre)=>({...pre,[key]:value}))
  }
  const handleTepdinhkem = (value) => {
    const urlCheck = queryString.parseUrl(decodeURI(value))
    if(!_.isEmpty(urlCheck.query)){
      setState((prev) => ({ 
        ...prev, 
        TepDinhKem: decodeURI(value),
        TenTep: urlCheck.query.id.replace(urlCheck.query.parent+'/','')
      }));
    }
  }
  return (
    <Dialog
      open={isThemVanBan}
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}
    >
      <DialogTitle>Thêm văn bản</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              freeSolo
              options={vanBan.map((x) => x.KyHieu)}
              inputValue={state.KyHieu}
              onChange={(e,val) => handleChange("KyHieu", val)}
              onInputChange={(e,val) => handleChange("KyHieu", val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Số/Ký hiệu"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MobileDatePicker
              label="Ngày ban hành"
              fullWidth
              inputFormat="DD/MM/YYYY"
              value={state.NgayBanHanh}
              onChange={(val) => handleChange("NgayBanHanh", val)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={LoaiVB}
              value={state.LoaiVB}
              onChange={(e,val) => handleChange("LoaiVB", val)}
              inputValue={inputCache.LoaiVB}
              onInputChange={(e,val) => handleInput("LoaiVB", val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Loại văn bản"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              // options={NoiBH.map((x) => x.value)}
              options={userInfo.roles}
              value={state.NoiBH}
              inputValue={inputCache.NoiBH}
              onChange={(e,val) => handleChange("NoiBH", val)}
              onInputChange={(e,val) => handleInput("NoiBH", val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Nơi ban hành"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              multiline
              maxRows={4}
              label="Trích yếu"
              value={state.TrichYeu}
              onChange={(val) => handleChange("TrichYeu", val.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={LinhVuc.map((x) => x.value)}
              value={state.LinhVuc}
              inputValue={inputCache.LinhVuc}
              onChange={(e,val) => handleChange("LinhVuc", val)}
              onInputChange={(e,val) => handleInput("LinhVuc", val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Lĩnh vực"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Link tài liệu đính kèm (SharePoint)"
              value={state.TepDinhKem}
              onChange={(val) => handleTepdinhkem(val.target.value)}
            />
            <Link to={state.TenTep}>{state.TenTep}</Link>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Lưu</Button>
        <Button onClick={handleClose}>Đóng</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ThemVanBanComponent;