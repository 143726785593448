import React from 'react'
import {FormControl,InputLabel,Select,MenuItem} from '@mui/material'

function SelectComponent(params) {
  const {label,value,values} = params
  const handleChange = (event) => {
    params.onChange(event.target.value);
  };

  return(
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        size="small"
        value={value}
        label={label}
        onChange={handleChange}
      >
        {values.map(x=>(
          <MenuItem value={x.value}>{x.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectComponent